
import { Action } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { CreateOfficePayload, Office, OfficeCreate, OfficeSetViewing } from "@/store/modules/office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
    },
})
export default class PageAdminOfficesCreate extends Vue {
    @Ref() form!: FormClass;

    @Action("office/create") createOffice!: OfficeCreate;
    @Action("office/setViewing") setViewing!: OfficeSetViewing;

    payload: CreateOfficePayload = {
        name: "",
    };

    private submit(form: FormClass) {
        this.createOffice(this.payload)
            .then((o: Office) => {
                form.reset();
                this.$toast.open({ message: this.$t("views.settings.admin-offices.create_success") as string, type: "success", position: "bottom-right" });
                this.setViewing(o);
                this.$emit("created");
            })
            .catch((errorResponse: ErrorResponse) => {
                this.$errorResponse = errorResponse;
                form.reset();
            });
    }
}
